import { getValueFromDto as getValue } from './_helpers.js';

export default class RentalItemType {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.wasteTypeId = getValue(dto, 'wasteTypeId', 'number', null);
		this.sortOrder = getValue(dto, 'sortOrder', 'number', 0);
		this.active = getValue(dto, 'active', 'boolean', true);
	}
}

export function displayRentalItemType(types, id) {
	return Array.isArray(types) && typeof id === 'number' && id > 0 ? types.find(x => x.id === id)?.name ?? '' : '';
}

export const rawPlaceholder = {
	id: -1,
	name: 'Loading',
	sortOrder: 0,
};
export const placeholder = Object.freeze(new RentalItemType(rawPlaceholder));
